<template>

    <div>
        <el-dialog title="请输入手机验证码" :visible.sync="dialogVisible" width="520px" center custom-class="codeDialog"
            style="top:20vh">
            <el-form :inline="true" :model="formInline" class="demo-form-inline">
                <el-form-item label="验证码">
                    <el-input v-model="formInline.smsCode" placeholder="请输入"
                        style="width: 260px;margin-left: 10px;"></el-input>
                    <el-button type="primary" @click="reGetCode" :disabled="timeout != 120"
                        style="width: 136px;margin-left: 12px;">{{ timeout != 120 ? timeout + '秒后重试' : '获取验证码' }}</el-button>

                </el-form-item>
            </el-form>
            <span slot="footer" class="dialog-footer">
                <el-button @click="dialogVisible = false">取消</el-button>
                <el-button type="primary" @click="getData"
                    :disabled="!formInline.smsCode">立即检测</el-button>
            </span>
        </el-dialog>
    </div>
</template>
<script>
import { authByCodeFromSecond,reSendCodeFromSecond} from "@/api/openNew.js"

export default {
    data() {
        return {
            dialogVisible: false,
            formInline: {
                smsCode: '',
            },
            timeout: 120,
            taskId:null,
            taxNo:null,
            programId:''
        };
    },
    methods: {

        async getData(){
        this.loading = this.$loading({
                lock: true,
                text:'验证中..',
                spinner: "el-icon-loading",
                background: "rgba(0, 0, 0, 0.7)",
                customClass:'loadingClass'
            });
            const params={
                taskId:this.taskId,
                smsCode:this.formInline.smsCode,
                taxNo:this.taxNo
            }
            const res=await authByCodeFromSecond(params)
            this.loading.close();

            if(res.code == 200 ){
                this.dialogVisible = false
                this.$emit('success')

            }else if(res.code == 5001 || res.code == -1) {
                    // this.$message.error(res.message)
                    this.$emit('messagePasswords',res.message)


                }
        },
        async reGetCode(){
            const params={
                taskId:this.taskId,
                programId:this.programId
            }
            const res=await reSendCodeFromSecond(params)
            if(res.code == 200 ){
                this.startTimeOut(true,1)
            }
        },
        startTimeOut(needReset,delayTime=0){
            if(needReset){
                this.timeout = 120 - delayTime
            }
            this.timer = setInterval(()=>{
                this.timeout--
                if(this.timeout == 0) {
                    clearInterval(this.timer)
                    this.timeout=120
                }
            },1000)
        },
        
        openDialog(e,taxNos,pId) {
            this.taskId = e
            this.taxNo = taxNos
            this.programId = pId||''

            if(this.timer){
                clearInterval(this.timer)
                this.startTimeOut(false)
                this.dialogVisible = true;
                return;
            }
            this.startTimeOut(true,1)

            this.dialogVisible = true;
        },
    }
};
</script>
<style lang="scss">
.loadingClass{
    z-index: 9999!important;
}
.codeDialog {
    border-radius: 8px;
    background-color: #FFFFFF;
    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.2);
}




.codeDialog.el-dialog .el-dialog__header {
    font-family: PingFang SC;
    color: #1D2129;
    font-weight: 600;
    font-size: 16px;
}

.codeDialog.el-dialog .el-dialog__header,
.codeDialog.el-dialog .el-dialog__footer {
    border: 0 !important;
    padding: 24px 24px 10px 24px;
}

.codeDialog.el-dialog .el-dialog__footer {
    padding: 24px 24px 24px 24px !important;

}

.dialog-footer .el-button {
    height: 32px;
    width: 80px;
    line-height: 32px;
    font-size: 14px;
    padding: 0;
    text-align: center;
    background-color: #FFFFFF;
    border: 1px solid #DFDFE0;
    font-family: PingFang SC;
    color: #1D2129;
    font-weight: 400;
    font-size: 14px;
}

.dialog-footer .el-button.el-button--primary:not([disabled]) {
    background-color: #165DFF;
    color: #FFFFFF;
    border: 0;
}

.dialog-footer .el-button.el-button--primary:disabled {
    background-color: #F4F6F8;
    color: #A4A6A9;
    border: 1px solid #DFDFE0;
}

.codeDialog.el-dialog--center .el-dialog__footer {
    text-align: right;
}

.codeDialog .el-dialog__body .el-form .el-form-item {
    margin-bottom: 0 !important;
    margin-right: 0 !important;
}

.codeDialog .el-dialog__body {
    padding: 24px 24px 0 24px;
}

.codeDialog .el-dialog__body .el-form .el-form-item .el-button {
    background-color: #FFFFFF;
    border: 1px solid #165DFF;
    color: #165DFF;
    height: 32px;
    line-height: 32px;
    padding: 0;
    text-align: center;
}
.codeDialog .el-dialog__body .el-form .el-form-item .el-input__inner{
    height: 32px;
    line-height: 32px;
}
.codeDialog .el-dialog__body .el-form .el-form-item .el-button:disabled {
    background-color: #F4F6F8;
    border: 1px solid #DFDFE0;

    color: #A4A6A9;
}
</style>