<template>
  <div class="mainBox">
    <div class="formDiv">
      <form autocomplete="off" class="container dali-style">
        <div class="toptips">
          <div>
            <div>1、在检测过程中，请勿使用该公司账号登录电子税务局，或者在其他平台进行数据授权，否则数据采集将被中断。</div>
            <div>2、当同一个办税员绑定多家企业时，系统不支持同时授权，否则可能导致数据采集中断，无法生成报告。</div>
          </div>
        </div>
        <div class="user field">
          <div class="label required">企业名称</div>
          <el-select v-model="companyInfo.companyName" filterable remote reserve-keyword
                     :disabled="$route.name == 'oemGuardV4_2' || $route.name == 'oemGuardV4_3'"
                     placeholder="请输入企业名称，最少4个字" ref="select" @visible-change="cancalReadOnly"
                     :remote-method="remoteMethod" @change="nameChange"
                     @hook:mounted="cancalReadOnly"
                     :loading="selectLoading">
            <el-option v-for="(item, index) in options" :key="item.creditCode" :label="item.name"
                       :value="index">
            </el-option>
          </el-select>
        </div>
        <div class="user field">
          <div class="label required">统一社会信用代码</div>
          <el-input v-model.trim="companyInfo.userName" disabled
                    placeholder="选择企业名称后自动带出，无需填写"></el-input>
        </div>
        <div class="user field">
          <div class="label required">电子税务局账号
            <i class="iconfont icon-icon_tips onlyMobile"
               @click="tipClick('电子税务局账号', '企业登录电子税务局网站的账号，一般为纳税人识别号、法人身份证、用户名等。')" style="display:none;
                            font-size: 14px;
                            color: #606368;
                            "></i>
            <el-tooltip class="item pc-device-only " effect="dark" placement="top-start">
              <div slot="content">
                企业登录电子税务局网站的账号，一般为纳税人识别号、法人身份证、用户名等。
              </div>
              <i class="iconfont icon-icon_tips onlyPc" style="
                        display:none;
              font-size: 14px;
              color: #606368;
            "></i>
            </el-tooltip>
          </div>
          <el-input v-model.trim="companyInfo.userName" disabled
                    placeholder="请输入电子税务局账号"></el-input>
        </div>
        <div class="user field">
          <div class="label required">委托人手机号/身份证号码/操作员代码</div>
          <el-input v-model.trim="companyInfo.userCode" placeholder="请从上述方式中任选一种进行输入"></el-input>
        </div>
        <div class="user field">
          <div class="label required">个人用户密码
            <i class="iconfont icon-icon_tips onlyMobile"
               @click="tipClick(null, '企业登录电子税务局网站的密码，如果忘记密码，请先在电子税务局页面找回密码后登录。')" style="
                            display:none;
                                font-size: 14px;
                                color: #606368;
                                "></i>
            <el-tooltip class="item pc-device-only " effect="dark" placement="top-start">
              <div slot="content">
                企业登录电子税务局网站的密码或个人密码，如果忘记密码，请先在电子税务局页面找回密码后登录。
              </div>
              <i class="iconfont icon-icon_tips onlyPC" style="
                                font-size: 14px;
                                color: #606368;
                                "></i>
            </el-tooltip>
          </div>
          <el-input v-model.trim="companyInfo.password" placeholder="请输入密码"
                    show-password></el-input>
        </div>
        <div class="user field">
          <div class="label required">登录身份</div>
          <el-select v-model="companyInfo.identityType" placeholder="请选择">
            <el-option v-for="item in identityTypeList" :key="item.value" :label="item.name"
                       :value="item.value"></el-option>
          </el-select>
        </div>
        <div class="user field" v-if="validateMethod == 2">
          <div class="label required">手机号</div>
          <el-input v-model.trim="companyInfo.phoneNumber" placeholder="请输入手机号码"></el-input>
        </div>
      </form>
    </div>
    <div class="buttonDiv">
      <div class="checks">
        <el-checkbox v-model="isChecked" @change="handleChange">我已阅读并同意</el-checkbox>
        <span class="check" @click="showLicen2"> 《授权协议》 </span>
      </div>
      <el-button v-if="$route.name == 'oemGuardV4'" type="primary" :disabled="isDisabled"
                 @click="toLogin">{{
        validateMethod ==
        1
        ? '立即检测' : '授权登录' }}
      </el-button>
      <div class="buttonGroup" v-else>
        <el-button class="shareButton onlyMobile" v-if="$route.name != 'oemGuardV4_3'"
                   @click="shareCodeDialog"><i class="iconfont icon-icon_share"
                                               style="margin-right:4px;font-size:14px;"></i>邀请他人
        </el-button>
        <el-button type="primary" :disabled="isDisabled" @click="toLogin">{{ validateMethod ==
          1
          ? '立即检测' : '授权登录' }}
        </el-button>
        <!-- 只在pc的邀请他人按钮 -->
        <span class="share pc-device-only" v-if="$route.name == 'oemGuardV4_2'"
              @click="shareCodeDialog"> <i
          class="iconfont icon-icon_share" style="margin-right:4px;font-size:14px;"></i>
                    邀请他人填写</span>
      </div>
    </div>
    <!-- <div v-if="errorPasswordString" class="errorPasswordString pc-device-only">
      <i class="iconfont icon-icon_tips"></i>
      <span>已连续多次输入个人用户密码错误，您可以通过“忘记密码”设置新密码。如果再次输入错误，您的账户会被锁定。</span>
    </div> -->
    <shouquan ref="shouquanRef" @read="checkChange" />
    <dialogs ref="dialogRef" @success="success" @messagePasswords="messagePasswords" />
    <agreementModal ref="agreementModalRef" @read="checkChange" />
    <getCodeDialog ref="getCodeDialogRef" @success="success" @messagePasswords="messagePasswords" />
  </div>
</template>
<script>
import shouquan from "../oem/shouquan.vue";
import dialogs from "./component/dialog.vue";
import diqu from "../oem/diqu";
import agreementModal from "../oem/agreementModal.vue";
import getCodeDialog from "@/components/getCodeDialog.vue";
import { isJSON } from "@/utils/helper.js"
import { Dialog } from "vant";

import {
  searchAuthSourceMsg,
  authAndSendCode,
  savePrepare,
  getAuthToken,
  savePrepareFromSecond,
  getPrepareInfo,
  authAndSendCodeFromSecond,
  getSharedPrepareInfo
} from "@/api/openNew.js"
import { cloneDeep } from "lodash";

export default {
  components: {
    shouquan, dialogs, diqu, agreementModal, getCodeDialog
  },
  data () {
    return {
      errorPasswordString: false,
      getInfo: {},
      programId: null,
      isChecked: false,
      isDemo: undefined,
      companyInfo: {
        companyName: '',
        userName: '',
        userCode: '',
        password: '',
        identityType: 3,
        phoneNumber: '',
      },
      appTypeName: '',
      validateMethod: 1,//验证方式 1密码 2验证码
      options: [],
      identityTypeList: [
        {
          name: "法人",
          value: 1,
        },
        {
          name: "财务负责人",
          value: 2,
        },
        {
          name: "办税人",
          value: 3,
        },
      ],
      selectLoading: false,
      loading: null,
      str: '',
    }
  },
  computed: {
    isMobileDevice () {
      return window.innerWidth < 768;
    },
    isDisabled () {
      const obj = cloneDeep(this.companyInfo)
      if (this.validateMethod == 1) {
        delete obj.phoneNumber
      }
      const isNotEmpty = Object.entries(obj).some((key) => {
        return key[1] === ''
      })
      if (!isNotEmpty && this.isChecked) {
        return false
      } else {
        return true
      }
    },
  },
  methods: {
    messagePasswords (e) {
        const params={
            messageInfo:'errorPassword',
            message:e
        }
      window.parent && window.parent.postMessage(JSON.stringify(params), "*");
      this.errorPasswordString = true;
    },
    showLicen2 () {
      // h5
      if (window.innerWidth < 750) {
        this.$refs.shouquanRef.openVanPopupShareShow()
        return
      } else {
        //pc
        //3邀请链接  2授权主页  1第三方
        if (this.$route.name === 'oemGuardV4_3') {
          this.$refs.agreementModalRef.openDialog()
        } else {
          window.parent && window.parent.postMessage("showLicen", "*");
        }
      }
    },
    async toLogin () {
      if (this.isDemo && this.isDemo !== 'null' && this.isDemo !== null) {
        this.loading = this.$loading({
          lock: true,
          text: `${ this.getInfo.startYear }年-${ this.getInfo.endYear }年数据正在采集中`,
          spinner: "el-icon-loading",
          background: "rgba(0, 0, 0, 0.7)",
        });
        setTimeout(() => {
          this.loading.close();
          this.deviceAdaptiveAlert("数据采集成功。", "温馨提示", {
            confirmButtonText: "确定",
            type: "success",
            callback: (action) => {
              this.$router.push({ name: "test-InvoiceUpload-oemV2" });
              window.parent && window.parent.postMessage('demoClick', "*");
            },
          });
        }, 60000)

        return;
      }
      this.loading = this.$loading({
        lock: true,
        text: this.validateMethod == 1 ? '检测中..' : '验证中..',
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)",
      });
      //第三方版本
      if (this.$route.name == 'oemGuardV4') {
        const params = {
          ...this.companyInfo,
          // encryptionType:0
          programId: this.programId
        }
        const res = await authAndSendCode(params)
        this.loading.close();

        if (res.code == 200) {
          //当前地区是否需要验证码 this.validateMethod == 1 无需验证码 this.validateMethod == 2 需要验证码
          if (this.validateMethod == 1) {
            this.success()
          } else {
            //根据返回值 validateMethod 来判断是否需要打开验证码弹框 res.data.validateMethod == 1 不需要验证码 res.data.validateMethod == 2 需要验证码
            if (res.data.validateMethod == 1) {
              this.success()
              return
            }
            this.$refs['dialogRef'].openDialog(res.data.taskId, this.companyInfo.userName, this.programId)
          }
        }
      } else {
        const params = {
          ...this.companyInfo,
          // encryptionType:0
          programId: this.programId
        }
        const res = await authAndSendCodeFromSecond(params)
        this.loading.close();

        if (res.code == 200) {
          //当前地区是否需要验证码 this.validateMethod == 1 无需验证码 this.validateMethod == 2 需要验证码
          if (this.validateMethod == 1) {
            this.success()
          } else {
            //根据返回值 validateMethod 来判断是否需要打开验证码弹框 res.data.validateMethod == 1 不需要验证码 res.data.validateMethod == 2 需要验证码
            if (res.data.validateMethod == 1) {
              this.success()
              return
            }
            //h5
            if (window.innerWidth < 750) {
              this.$refs['dialogRef'].openDialog(res.data.taskId, this.companyInfo.userName, this.programId)

            } else {
              this.$refs['getCodeDialogRef'].openDialog(res.data.taskId, this.companyInfo.userName, this.programId)

            }

          }
        } else if (res.code == 5001 || res.code == -1) {
          // this.$message.error(res.message)
          this.messagePasswords(res.message)


        }


      }


    },
    remoteMethod (query) {
      if (query.length < 4) return;
      const params = {
        keyword: query,

      };
      searchAuthSourceMsg(params).then((res) => {
        this.options = res.data;
      });
    },
    nameChange (e) {
      this.companyInfo.userName = this.options[e].taxNo;
      this.companyInfo.companyName = this.options[e].name;
      this.validateMethod = this.options[e].validateMethod
    },
    cancalReadOnly (value) {
      this.$nextTick(() => {
        if (!value) {
          const { select } = this.$refs;
          const input = select.$el.querySelector(".el-input__inner");
          input.removeAttribute("readonly");
          const check = select.$el.querySelector(".el-checkbox__inner");
          check.removeAttribute("readonly");
          // this.$refs.select.blur();  根据tip自行判断是否添加
        }
      });
    },
    checkChange () {
      this.isChecked = true;
    },
    handleChange (e) {
      this.showLicen2()
      if (e) {
        this.isChecked = false
      }
    },
    async savePrepareApi () {
      // this.str = encodeURIComponent(this.$route.query.redirect_uri)
      this.str = this.$route.query.redirect_uri
      const params = {
        state: this.$route.query.state,
        goodsId: this.$route.query.goodId,
        redirectUri: decodeURIComponent(this.str)
        // redirectUri:decodeURIComponent(this.$route.query.redirect_uri)
      }
      const res = await savePrepare(params)
      if (res.code == 200) {
        this.programId = res.data.programId
      }
      console.log(res)

    },
    async receiveMsg (e) {
      if (isJSON(e.data)) {
        let info = JSON.parse(e.data)
        if (info.messageInfo == 'setMessage') {
          this.getInfo = info
          this.appTypeName = info.appTypeName
          this.companyInfo.companyName = info.companyName
          this.companyInfo.userName = info.companyNo
          this.programId = info.programId || ''
          this.isDemo = info.isDemo || false
          await this.getToken(info.authorization)
          await this.saveCompanny(info)
          const res = await getPrepareInfo({ programId: this.programId })
          if (res.code == 200) {
            this.validateMethod = res.data.validateMethod
          }
        }
      } else {
        if (e.data == 'message') {
          this.isChecked = true
        }
      }
    },
    async getToken (e) {
      const params = {
        appTypeName: this.appTypeName,
        authorization: e
      }
      const res = await getAuthToken(params)
      if (res.code == 200) {
        localStorage.setItem("accessToken", res.data.accessToken)
      }
    },
    async saveCompanny (e) {
      const params = {
        programId: e.programId || '',
        taxNo: e.companyNo,
        companyName: e.companyName,
        startDate: e.startYear,
        endDate: e.endYear,
        appTypeName: this.appTypeName,
        remainDays: e.remainDays ? e.remainDays : ''
      }
      if(this.appTypeName == 'lvat' || this.appTypeName == 'eit'){
        params.state=JSON.stringify({projectId:this.getInfo.projectId||''})
      }
      const res = await savePrepareFromSecond(params)
      if (res.code == 200) {
        this.programId = res.data.programId
        const params = {
          messageInfo: 'setProgramId',
          value: this.programId
        }
        window.parent && window.parent.postMessage(JSON.stringify(params), "*");

      }

    },

    tipClick (title, message) {
      if (window.innerWidth < 768) {
        let realTile = this.areaIndex == 0 && this.thirdDataSource == "DB_KUANGU" ? '个人用户密码' : '电子税务局密码'
        Dialog.alert({
          title: title != null ? title : realTile,
          message: message,
          confirmButtonText: '知道了',
          confirmButtonColor: '#165DFF',
          className: 'vantDialog'
        }).then(() => {
          // on close
        });
      }
    },

    submitSucces () {
      window.parent && window.parent.postMessage("thirdParty-submit-success", "*");
    },


    shareCodeDialog () {
      window.parent && window.parent.postMessage("shareCode", "*");
    },
    success () {
      //如果是土增或者企税项目，则需要跳转到自己的提交页面
      if (this.appTypeName == 'lvat' || this.appTypeName == 'eit') {
        this.submitSucces()
      }
      if (window.innerWidth < 750) {
        if (this.$route.name === 'oemGuardV4') {

          this.$router.replace({
            name: "oemGuardV4Success", query: {
              redirect_uri: decodeURIComponent(this.str)
            }
          })
        } else {
          this.$router.replace({
            name: "oemGuardV4Success"
          })
        }

        return
      }
      this.$router.push({
        name: "test-InvoiceUpload-oemV2",
        query: {
          from: this.appTypeName
        }
      });
    },
    deviceAdaptiveAlert (message, title, options = {}) {
      if (!this.isMobileDevice) {
        this.$alert(message, title, options);
      } else {
        Dialog.alert({
          title: title,
          message: message,
          confirmButtonText: options.confirmButtonText,
        }).then(() => {
          if (options.callback) {
            options.callback();
          }
        });
      }
    },
  },

  async mounted () {

    window.addEventListener('message', this.receiveMsg, false);

    //三方授权
    if (this.$route.name == 'oemGuardV4') {
      let accessToken = this.$route.query.accessToken
      localStorage.setItem('accessToken', accessToken)
      //获取三方授权信息并保存
      await this.savePrepareApi()
    } else if (this.$route.name == 'oemGuardV4_3') {
      //如果是二方邀请页面则，从接口获取信息并保存在本地

      const params = {
        uniqueCode: this.$route.query.uniqueCode
      }
      const res = await getSharedPrepareInfo(params)
      if (res.status == 200) {
        localStorage.setItem('share-accessToken', res.headers['share-accesstoken'])
        if (res.data.code == 200) {
          this.appTypeName = res.data.data.appTypeName
          this.companyInfo.companyName = res.data.data.companyName
          this.companyInfo.userName = res.data.data.taxNo
          this.programId = res.data.data.programId
          this.validateMethod = res.data.data.validateMethod

        }

      }

    }

  },
}
</script>
<style>
.el-popover,
.el-popoer {
  padding: 0px;
  box-shadow: none;
  border: 0;
}

.el-popper[x-placement^=right] .popper__arrow {
  boder-right-color: #D1D2D4 !important;
}

.el-icon-loading {
  font-size: 40px;
  margin-bottom: 10px;
}

.vantDialog .van-dialog__header {
  color: #1D2129;
  font-weight: bold;
  font-family: PingFang SC, PingFang SC;
  font-size: 17px;

}

.vantDialog .van-dialog__message {
  color: #606368;
  font-weight: 400;
  font-family: PingFang SC, PingFang SC;
  font-size: 17px;

}
</style>
<style scoped lang="scss">
.errorPasswordString {
  color: #F54242;
  margin-top: 20px;
  display: flex;
  align-items: center;
  margin-left: 260px;
  i {
    margin-right: 4px;
  }
}
.diquImg {
  width: 600px;
  box-shadow: 0px 3px 12px 1px rgba(0, 0, 0, 0.16);
}
.mainBox {
  font-size: 14px;
  box-sizing: border-box;
  padding: 0.27rem;
  padding-top: 0rem;
  padding-right: 0;
  height: 100vh;
  background-color: white;
  position: relative;
  .onlyPc {
    display: inline !important;
  }
  .dali-style /deep/ .el-input,
  .dali-style /deep/ .el-select {
    width: 360px;
  }
  .dali-style .footer2 {
    margin-left: 180px;
  }
  .onlyMobile {
    display: none;
  }
  @media screen and (max-width: 768px) {
    padding: 0.27rem;
    .formDiv {
      padding-right: 0.27rem;
      overflow: auto;
      height: calc(100vh - 1.85rem);
    }
    .onlyPc {
      display: none !important
    }
    .onlyMobile {
      display: inline !important
    }
    .pc-device-only {
      display: none !important;
    }
    .buttonDiv {
      position: absolute !important;
      box-sizing: border-box;
      margin-left: 0 !important;
      padding-left: 0.27rem;
      padding-right: 0.27rem;
      padding-top: 0.21rem !important;
      // padding-top: 0rem!important;
      box-shadow: 0px -1px 0px 0px rgba(14, 20, 41, 0.06);
      background-color: #FFFFFF;
      position: absolute !important;
      bottom: 0;
      left: 0;
      height: 1.85rem;
      width: 100% !important;
      .buttonGroup {
        display: flex;
        justify-content: space-between;
        .shareButton {
          border-radius: 5px;
          background-color: rgba(22, 93, 255, 0.1);
          height: 44px;
          color: #165DFF;
          border: 0;
        }
        /deep/ .el-button.el-button--primary {
          background-color: #165DFF;
          outline: none;
          height: 44px;
          border: none;
        }
        /deep/ .el-button:only-child,
        /deep/ .el-button.el-button--primary:only-child {
          width: 100% !important;
        }
        /deep/ .el-button,
        /deep/ .el-button.el-button--primary {
          width: 48% !important;
        }
      }
      /deep/ .el-button.el-button--primary {
        background-color: #165DFF;
        outline: none;
        border: none;
        width: 100% !important;
      }
      /deep/ .el-button.el-button--primary.is-disabled {
        background-color: rgba(22, 93, 255, 0.2) !important;
        border: 0 !important;
        color: white !important;
      }
      .checks {
        margin-bottom: 0rem !important;
        /deep/ .el-checkbox {
          margin-bottom: 0.29rem;
        }
        /deep/ .el-checkbox__input.is-checked + .el-checkbox__label {
          color: #1D2129;
        }
        /deep/ .is-checked .el-checkbox__inner {
          background-color: #165DFF;
        }
        .check {
          cursor: pointer;
          color: #165DFF;
        }
      }
    }
    .user {
      margin: 0;
    }
    .field {
      display: flex;
      flex-direction: column;
      margin: 10px 0 20px;
    }
    .label {
      width: 100% !important;
      text-align: left !important;
      margin-bottom: 10px;
      padding-left: 5px;
    }
    .btn-refresh {
      width: 100%;
      border: 1px solid #aaa;
      margin: 10px 0;
    }
    :deep(.el-input),
    :deep(.el-select) {
      width: 100% !important
    }
    .title,
    .toptips {
      display: none !important;
    }
    .dali-style /deep/ .el-input,
    .dali-style /deep/ .el-select,
    .dali-style .footer2 {
      width: 100%;
    }
    /deep/ .el-button {
      //padding:0px!important;
      //margin-left:0px!important;
    }
  }
  /deep/ .el-popper[x-placement^=right] .popper__arrow {
    boder-right-color: #D1D2D4 !important;
  }
  .toptips {
    margin-left: 24px;
    margin-bottom: 24px;
    width: 750px;
    //height: 36px;
    display: flex;
    line-height: 22px;
    background: #FFF7E8;
    border-radius: 4px 4px 4px 4px;
    opacity: 1;
    border: 1px solid rgba(253, 179, 32, 0.5);
    padding: 8px 14px;
    > i {
      margin-right: 8px;
      color: #FDB320;
    }
    > span {
      font-size: 14px;
      font-family: PingFang SC, PingFang SC;
      font-weight: 400;
      color: #333333;
      line-height: 22px;
    }
  }
  /deep/ .el-button {
    //padding:8px 12px;
    //margin-left:20px;
  }
  .title {
    /deep/ .el-button {
      i {
        font-size: 14px;
      }
    }
    color: rgba(0, 0, 0, 0.8);
    padding: 16px 24px;
    font-size: 18px;
    font-weight: 600;
    border-bottom: 1px solid #eeeeee;
    .tips {
      font-size: 14px;
      color: #f5222d;
      margin-left: 16px;
    }
    .tiptitle {
      color: #0f3bec;
      font-size: 14px;
      margin-left: 20px;
    }
  }
  .buttonDiv {
    position: relative;
    margin-left: 250px;
    width: auto;
    margin-top: 24px;
    .pc-device-only.share {
      cursor: pointer;
      display: inline-block;
      color: #165DFF;
      font-size: 14px;
      margin-left: 154px;
    }
    /deep/ .el-button.el-button--primary {
      background-color: #165DFF;
      outline: none;
      border: none;
      height: 36px;
      width: 120px;
    }
    /deep/ .el-button.el-button--primary.is-disabled {
      background-color: #F4F6F8;
      color: #A4A6A9;
      border: 1px solid #DFDFE0;
    }
    .checks {
      /deep/ .el-checkbox {
        margin-bottom: 0.29rem;
      }
      /deep/ .el-checkbox__input.is-checked + .el-checkbox__label {
        color: #1D2129;
      }
      /deep/ .is-checked .el-checkbox__inner {
        background-color: #165DFF;
      }
      .check {
        cursor: pointer;
        color: #165DFF;
      }
    }
  }
}
.user {
  margin-bottom: 0.24rem;
  .label {
    font-family: PingFang SC;
    color: #1D2129;
    font-weight: 400;
    font-size: 14px;
    margin-bottom: 0.12rem;
  }
  .label.required::before {
    content: "*";
    color: red;
    position: relative;
    right: 0px;
    top: 1px;
    margin-right: 4px;
  }
  .label {
    width: 250px;
    display: inline-block;
    text-align: right;
    padding-right: 5px;
    position: relative;
  }
  .input {
    width: 40%;
  }
}
</style>
