<template>
    <div>
        <van-dialog v-model="show" title="请输入手机验证码" show-cancel-button   :showCancelButton="false" :showConfirmButton="false" width="320px" :closeOnClickOverlay="true">
            <div class="dialogMain">
                <codeInput  ref="codeInputRef" @inputChange="inputChange"/>
                <div v-if="timeout!=0 && timeout!=120" style="text-align: center;margin-bottom: 24px;">{{ timeout }}秒后再试</div>
                <div  v-if="timeout==120" style="text-align: center;margin-bottom: 24px;">   
                    <el-button  type="text" @click="reGetCode">重新获取验证码</el-button>

                </div>
                <el-button :disabled="isdisabled" @click="getData">立即检测</el-button>
            </div>
        </van-dialog>
    </div>
</template>
<script>
import codeInput from "./codeInput.vue" 

import { authByCode,reSendCode,reSendCodeFromSecond,authByCodeFromSecond} from "@/api/openNew.js"
export default {
    components:{codeInput},
    data() {
        return {
            show: false,
            taskId:null,
            taxNo:null,
            timeout:120,
            isdisabled:true,
            programId:'',
        }
    },
    mounted() {
        // this.startTimeOut()
        
    },
    methods:{

        async reGetCode(){
            const params={
                taskId:this.taskId,
                programId:this.programId
            }

            let res
            if(this.$route.name =='oemGuardV4'){
                res=await reSendCode(params)
            }else{
                res=await reSendCodeFromSecond(params)
            }
            if(res.code == 200 ){
                await this.startTimeOut()
            }
        },
        inputChange(e){
            console.log(e)
            if(e==6){
                this.isdisabled = false

            }else{
                this.isdisabled = true
            }
        },
        startTimeOut(){
            this.timeout = 120
            let timer = setInterval(()=>{
                this.timeout--
                if(this.timeout == 0) {
                    clearInterval(timer)
                    this.timeout=120
                }
            },1000)
        },
        openDialog(e,taxNos,pId){
            this.startTimeOut()
            this.taskId = e
            this.taxNo = taxNos
            this.programId = pId
            this.show = true
        },
        confirm(){
            // 点击确定按钮后执行的操作
        },
       async getData(){
        this.loading = this.$loading({
                lock: true,
                text:'验证中..',
                spinner: "el-icon-loading",
                background: "rgba(0, 0, 0, 0.7)",
                customClass:'loadingClass'
            });

            const params={
                taskId:this.taskId,
                smsCode:this.$refs.codeInputRef.data.verifyCodeTotal,
                taxNo:this.taxNo
            }
            let res
            if(this.$route.name =='oemGuardV4'){
                res=await authByCode(params)
            }else{
                res=await authByCodeFromSecond(params)
            }
            this.loading.close();
            if(res.code == 200 ){
                this.show = false
                this.$emit('success')

            }else if(res.code == 5001 || res.code == -1) {
                    // this.$message.error(res.message)
                    this.$emit('messagePasswords',res.message)



                }
        }
    }
}
</script>
<style>
.loadingClass{
    z-index: 9999!important;
}</style>
<style scoped lang="stylus">

.dialogMain{
    padding: 0.27rem;
    >>>.el-button.el-button--default{
        background-color: #165DFF;
        outline:none;
        border:none;
        color: #fff;
        width :100%;
        margin-bottom:0.03rem;
    }
    >>>.el-button.el-button--default.is-disabled{
        background-color: rgba(22, 93, 255, 0.2);
        color: #FFFFFF;
        
    }
}
</style>